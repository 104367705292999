import logo from './logo.svg';
import './App.css';

import Homepage from './pages';

function App() {
  return (
  <div>
  <div class="grid grid-cols-3 gap-4">
    <div class="col-span-1">
      <h1>Frontend</h1>
    </div>
    <div class="col-span-1">
      <h1>Frontend</h1>
    </div>
    <div class="col-span-1">
      <h1>Frontend</h1>
    </div>
  </div>
  </div>
  );
}

export default App;
