import React from 'react';
import './Newsroom.css';
import image12 from "../../assets/aicte2.png"
import logo from "../../assets/home_logo.svg"
import {Nav2, Footer, Header2, PressRelease, Sidemenu} from '../../components';
import datebox from '../../assets/datebox.svg'

const Newsroom = () => {
  return (
    <div className='bg-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl dark:text-white'>
      <Nav2/>
      <Header2 name="News Page" quote="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."/>    
      
      {/* FOLD ONE */}
      <div class="grid md:grid-cols-3 grid-cols-1 gap-4">
      <div className=" col-span-1 text-center font-medium mx-8">
          {/* <div >
            <h3 className='font-serif dark:text-amber-300'>NEWSROOM</h3>
          </div>

          <div className="greenlin text-center px-2">
           <ul className='pb-3'>
             <li>OVERVIEW</li>
             <li>PRESS RELEASE</li>
             <li>PHOTO GALLERY</li>
             <li>VIDEO GALLERY</li>
             <li>MEDIA CONTACT</li>
             <li>EVENTS</li>
           </ul>
          </div> */}
          <Sidemenu/>
        </div>

        <div className="col-span-2">
          <h2 className='font-serif dark:text-amber-300'>IN THE NEWS</h2>
          <div className="">
            <h3 className="font-serif text-5xl dark:text-green-300">Monday, 12 October 2020, Decca News</h3>
            <div className="verline">
            <div className=""> New Delhi: The All India Council for Technical Education (AICTE) announced on Monday that it has partnered with Microsoft to empower students and educators with future skills. As part of this partnership, more than 1,500 Microsoft course modules are made available to students and educators free of charge via the ELIS of AICTE’s e-learning portal. Microsoft’s learning resource center, Microsoft Learn, is integrated with the ELIS platform to provide access to personalized learning pathways and resources for students, which include a wide range of technologies, including AI, IoT, computer science, and cloud computing, among others.</div>
          </div>
          </div>
          <div className="">
            <h3 className="font-serif text-5xl dark:text-green-300">Tuesday, 8 October 2019, News18</h3>
            <div className="verline">
            <div className="">  New Delhi: The All India Council of Technical Education (AICTE) has directed engineering colleges and technical institutions to allow student entrepreneurs to sit for examination even if their attendance is short. The colleges have also been asked to explore provisions of on-campus accommodation to student entrepreneurs and permitting semester break for working on their startups.</div>
          </div>
          </div>
          <div className="">
            <h3 className="font-serif text-5xl dark:text-green-300">Tuesday, 8 October 2019, Times of India</h3>
            <div className="verline">
            <div className=""> AGRA: To help all the nearby Agra villages to overcome their problems, students of engineering colleges will help them find solutions and also, work for their development. Each of the engineering college has been asked to adopt at least five villages for the overall development. Some of the engineering colleges have started a survey to identify the villages.</div>
          </div>
          </div>

        </div>
      </div>

      
      {/* Press Release */}
      <PressRelease/>

      
      <Footer/>  
    </div> 
  )
}

export default Newsroom