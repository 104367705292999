import React from "react";
import "./Sidemenu.css";

// Icons
// import flag_dark from '../../assets/icons/Flag.svg';
// import flag_white from '../../assets/icons/Flag-white.svg';

// React Router
import { Route, Link } from "react-router-dom";
import { ExternalLink } from 'react-external-link';

function Sidemenu({todo,acc_links}) {
  const [ann_text] = React.useState([
    {
      text: "OVERVIEW",
      acc_links: "https://www.aicte-india.org/sites/default/files/Public%20Notice%20for%20website%20English%20and%20Hindi%20%281%29%20%281%29.pdf"
    },
    {
      text: "ADARSH IS GOOD BOY",
      acc_links: "https://www.aicte-india.org/sites/default/files/Revised%20Ciruclar%20Academic%20Year%202022-23.pdf"
    },
    {
      text: "ADARSH IS GOOD BOY",
      acc_links: "https://www.aicte-india.org/sites/default/files/AICTE_Cal_2022_Final_31.12.2021_compressed_0.pdf"
    },
    {
      text: "ADARSH IS GOOD BOY",
      acc_links: "https://www.aicte-india.org/sites/default/files/Advt-Consultant.pdf"
    },
    {
      text: "ADARSH IS GOOD BOY",
      acc_links: "https://aicte-india.org/sites/default/files/Circular%20dated%2024.03.2022.pdf"
    },
    {
      text: "ADARSH IS GOOD BOY",
      acc_links: "https://www.aicte-india.org/sites/default/files/approval/2022-23/Approval%20Process%20Handbook2022-23.pdf"
    },
    
  ]);

  function Smenu({ todo, index }) {
    return (
        <div className=" ">
            <div className=" p-2  ">
                {/* <img src={flag_dark} alt="img tag"/> */}
                <ExternalLink href={todo.acc_links}><span className="inline-block">{todo.text}</span></ExternalLink>
            </div>
        </div>
    );
  }
  return (
    <div className=" px-6">
        <div >
        <h3 className="capitalize text-center dark:text-amber-300 mt-1 font-bold">Education</h3>
        <div className="font-serif text-xl p-2">
            {ann_text.map((todo, index) => (
            <ul className="border-b-2 border-black text-center pt-4 dark:border-green-300 "><li>
              <Smenu
                key={index}
                index={index}
                todo={todo}
            />
           
            </li></ul>
            ))}
        </div></div>
    </div>
  );
}

export default Sidemenu;