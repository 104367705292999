// Import Libraires
import React from "react";
// import "./Circulars.css";

// Icons
import docs_dark from '../../assets/icons/Text_Docs.svg';
import docs_white from '../../assets/icons/Text_Docs-white.svg';

// External links
import { ExternalLink } from 'react-external-link';

function Circulars() {
  const [cir_text] = React.useState([
    {
      text: "Circular for Implementation of AICTE Student Learning Assessment Project (PARAKH) in AICTE Approved Institution",
      qLinks: "https://aicte-india.org/sites/default/files/IMG_0001_0.pdf#overlay-context=",
    },
    {
      text: 'Uploading data on "All India Survey on Higher Education"',
      qLinks: "https://www.aicte-india.org/sites/default/files/Circular%20%282%29.PDF",
    },
    {
      text: "Maintenance of safe working environment for female employees at workplace.",
      qLinks: "https://www.aicte-india.org/sites/default/files/Circular-%20Maintenance%20of%20safe%20working%20environment%20of%20femal%20employees%20at%20workplace..pdf",
    },
    {
      text: "Displaying the Mandatory Disclosure on Portal",
      qLinks: "https://www.aicte-india.org/sites/default/files/MANDATORY%20DISCLOSURE%20REGARDING%2007.03.2022_20220307_0001.pdf",
    },
    {
      text: "Essential Requirements for Technical Institutions",
      qLinks: "https://www.aicte-india.org/sites/default/files/Circular%20-%20Essential%20Requirements%20-%2008-03-2022.pdf",
    },
  ]);

  function Circs({ todo }) {
    return (
        <div className="bg-white m-2">
            <div className="flex p-2 m-2 gap-3">
                <img src={docs_dark} alt="img tag"/>
                <ExternalLink href={todo.qLinks}><span className="inline-block">{todo.text}</span></ExternalLink>
            </div>
        </div>

    );
  }
  return (
    <div>
        <h3 className="capitalize text-center dark:text-amber-300 mt-1">circulars</h3>
        <div className="p-2">
            {cir_text.map((todo, index, qLinks) => (
            <Circs
                key={index}
                index={index}
                todo={todo}
                xlink={qLinks}
            />
            ))}
        </div>
    </div>
  );
}

export default Circulars;