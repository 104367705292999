import React from 'react'
import './schemes.css'
import image0 from "../../assets/scheme/aicte2.png"
import logo1 from "../../assets/scheme/home_logo.svg"
import image1 from "../../assets/scheme/scheme1.png"
import image2 from "../../assets/scheme/scheme2.png"
import image3 from "../../assets/scheme/scheme3.png"
import image4 from "../../assets/scheme/scheme4.png"
import image5 from "../../assets/scheme/scheme5.png"
import datebox from '../../assets/datebox.svg'

import { Nav2, Footer, Header1,PressRelease} from '../../components'

const Schemes = () => {
  return (
    <div className="bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2/>
      <Header1 name="SCHEMES" quote="Learn more about the various student and Faculty development by AICTE"/>

      <div className="grid md:grid-cols-4 grid-cols-1">
      <div className="col-span-1 px-6">
          <div className="font-serif text-center py-6 font-bold dark:text-amber-300">
            <h3>SCHEMES</h3>
          </div>

          <div className="over text-center">
           <ul>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">STUDENT DEVELOPMENT SCHEMES</li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">FACULTY DEVELOPMENT SCHEMES</li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">INSTITUITIONAL SCHEMES</li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">RESEARCH & INNOVATION DEVELOPMENT SCHEMES</li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">GENERAL SCHEMES</li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2">NEWS & ANNOUNCEMENTS</li>
           </ul>
          </div>
        </div>
        <div className="col-span-3 justify-items-center">
        <div className="grid md:grid-cols-4 grid-cols-1 my-8 ">
          <div className="col-span-1 mx-auto">
            <img src={image1} alt="" />
          </div>
          <div className="col-span-3 ">
            <h3 className="font-serif text-5xl font-medium pt-4 dark:text-amber-300">STUDENTS DEVELOPMENT SCHEMES</h3>
            <p className="font-medium text-lg text-center p-5">Explore various, schemes and special scholarship run by AICTE to promote technical education</p>
            <div className=" flex flex-bottom">
              <button className="w-auto bg-transparent border-2 px-3 py-2 mb-2 dark:text-white  text-black border-black">Read More</button>
          </div> </div>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 my-8 ">
          <div className="col-span-1 mx-auto">
            <img src={image2} alt="" />
          </div>
          <div className="col-span-3 ">
            <h3 className="font-serif text-5xl font-medium pt-4 dark:text-amber-300">FACULTY DEVELOPMENT SCHEMES</h3>
            <p className="font-medium text-lg text-center p-6">Explore various, schemes and special scholarship run by AICTE to promote technical education</p>
            <button className="w-auto bg-transparent border-2 px-3 py-2 m-1 dark:text-white text-black border-black">Read More</button>
          </div>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 my-8 ">
          <div className="col-span-1 mx-auto">
            <img src={image3} alt="" />
          </div>
          <div className="col-span-3 ">
            <h3 className="font-serif text-5xl font-medium pt-4 dark:text-amber-300">INSTITUITIONAL SCHEMES</h3>
            <p className="font-medium text-lg text-center p-6">Explore various, schemes and special scholarship run by AICTE to promote technical education</p>
            <button className="w-auto bg-transparent border-2 px-3 py-2 m-1 dark:text-white  text-black border-black">Read More</button>
          </div>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 my-8 ">
          <div className="col-span-1 mx-auto">
            <img src={image4} alt="" />
          </div>
          <div className="col-span-3 ">
            <h3 className="font-serif text-5xl font-medium pt-4 dark:text-amber-300">RESEARCH & INNOVATION DEVELOPMENT SCHEMES</h3>
            <p className="font-medium text-lg text-center p-6">Explore various, schemes and special scholarship run by AICTE to promote technical education</p>
            <button className="w-auto bg-transparent border-2 px-3 py-2 m-1 md:mx-auto dark:text-white text-black border-black">Read More</button>
          </div>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 my-8 ">
            <div className="col-span-1 mx-auto">
              <img src={image5} alt="" />
            </div>
          <div className="col-span-3 ">
            <h3 className="font-serif text-5xl font-medium pt-4 dark:text-amber-300">GENERAL SCHEMES</h3>
            <p className="font-medium text-lg text-center p-6">Explore various, schemes and special scholarship run by AICTE to promote technical education</p>
            <button className="dark:text-white w-auto bg-transparent border-2 px-3 py-2 m-1  text-black border-black ">Read More</button>
          </div>
        </div>
        </div>
      </div>

      <div className='pb-2 container p-8'>
      <h2 className='font-serif pb-3 font-medium dark:text-amber-300'>News & Announcements</h2>
      <div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1 mb-5">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
      <div className="my-8">
        <button className='w-auto px-4 py-2 m-1 '>Read More</button>
      </div>
    </div>


      <Footer/>
    </div>
  )
}

export default Schemes