// Contact Form for Contact Page
import React from 'react';

const ContactForm = () => {
    return(<div className='pr-5'>
        <h4>Send us your Queries</h4>
        <h5>Contact Information</h5>
        <form>
            <label>Name: </label>
            <input type="text" name="name" placeholder='Your Name' /><br/>
            <label>Email: </label>
            <input type="email" name="email" placeholder='johndoe@example.com' /><br/>
            <label>Phone: </label>
            <input type="text" name="tel" placeholder='011-26131576' /><br/>
            <label>Type of Your Query: </label>
            <select required>
                <option disabled selected>Type of Your Query</option>
                <option value="lime">Lime</option>
                <option value="coconut">Coconut</option>
                <option value="mango">Mango</option>
            </select><br/>
            <label>Your Message: </label><br/>
            <textarea type="textarea" name="email" placeholder='johndoe@example.com' /><br/>
            <input className='button black hover:white' type="submit" value="Submit" />
        </form>
    </div>
    )
}

export default ContactForm;