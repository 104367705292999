import React from 'react';
import '../index.css'

function pageHeader2 (title) {
  return (
    <div className='pageHeader_main bg-hero-pattern bg-blend-overlay grid grid-cols-2 content-center bg-slate-700'>
    <div className='col-span-2'>
      <div className='text-center text-white'>
          <h2 className='font-serif'>{title.name}</h2>
          <p>Place for the Breadcrumbs</p>
          <p className='text-xl italic m-2 font-medium'>{title.quote}</p>
      </div>
    </div>
    </div>
  )
}

export default pageHeader2