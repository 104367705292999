import React from 'react'
import './Opportunities.css'

import { Nav2, Footer, Header2 } from '../../components'
import flag from "../flag.svg"

const Opportunities = () => {
  return (
    <div className='bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl'>
      
        <Nav2/>
        <Header2 name="OPPORTUNITIES" quote="The word student is etymologically derived through Middle English from the Latin second-type conjugation verb studēre, meaning to direct ones zeal at hence a student could be described as one who directs zeal at a subject. In its widest use , student is used for anyone who is learning."/>
        <div className="grid md:grid-cols-4 grid-cols-1">
          
        <div className="col-span-1 px-6">
          <div className="font-serif text-center py-8 font-bold ">
            <h3 className='text-5xl text-w dark:text-amber-300'>OPPORTUNITIES</h3>
          </div>

          <div className="opp text-center">
           <ul>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#"> OVERVIEW</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#"> FOR FACULTY</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2 "><a href="#">FOR STUDENTS</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2 "><a href="#">FOR INSTITUITION</a></li>
           </ul>
          </div>
        </div>
          <div className="col-span-3 px-6 ">
            
            <h3 className="text-center font-serif font-medium pt-6 pb-6 text-sky-900 dark:text-amber-300">LEARNING & GROWING</h3>
            <p className="pb-10 text-justify">Learning is acquiring new knowledge, behaviors, skills, values or preferences. It may involve processing different types of information. Learning functions can be performed by different brain learning processes, which depend on the mental capacities of learning subject/agent, the type of knowledge which has to be acquitted, as well as on socio-cognitive and environmental circumstances.</p>
            <p className="pb-10">Human learning may occur as part of education or personal development. It may be goal-oriented and may be aided by motivation. The study of how learning occurs is part of neuropsychology, educational psychology, learning theory, and pedagogy.</p>
            <p className="pb-20">learning theory, and pedagogy. Learning may occur as a result of habituation or classical conditioning, seen in many animal species, or as a result of more complex activities such as play, seen only in relatively intelligent animals and humans. Learning may occur consciously or without conscious awareness. There is evidence for human behavioral learning prenatally, in which habituation has been observed as early as 32 weeks into gestation, indicating that the central nervous system is sufficiently developed and primed for learning and memory to occur very early on in development. </p>
          </div>
        </div>

        <div className="p-5">
        <div className="border border-black p-10 dark:border-green-300">
       
        <h4 className="font-serif text-center  font-medium pb-4 text-sky-900 dark:text-amber-300">IMPORTANT</h4>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-8 justify-items-center ">
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50 ">
            <p className="text-center dark:text-black">INAE 4th INAE Youth Conclave scheduled on September 24, 2021 jointly with IIT Bombay; NITIE, Mumbai and ICT, Mumbai</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50">
            <p className="text-center dark:text-black">Grand Challenge for design & development for Mobile accessories products based on Li-ion Cells- seeking participation</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50">
            <p className="text-center dark:text-black">INAE 4th INAE Youth Conclave scheduled on September 24, 2021 jointly with IIT Bombay; NITIE, Mumbai and ICT, Mumbai</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50">
            <p className="text-center dark:text-black">Reservation of Seats in Degree Level technical courses for States/ UTs lacking in such facilities for academic sessions 2021-22</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50">
            <p className="text-center dark:text-black">Internship & Project Training for Diploma/UG/PG Students of Engineering/Finance/Management/Law stream at ALTTC, BSNL, Ghaziabad</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
          
          <div className="border-2 border-black p-3 shadow-lg bg-amber-50">
            <p className="text-center dark:text-black">ISG-UBU Invites Indian-Mekong Scholars to Join the Project</p>
            <div className="pt-5 flex items-center justify-center">
            <button className="p-3 ">READ MORE</button>
            </div>
          </div>
        </div>
        </div>
        </div>
        <div className="md:w-1/2 mx-auto">

          <div className=" py-7 border-y-2 border-black my-7 dark:border-green-300">
            
            <h3 className="font-serif text-center font-medium pb-5 text-sky-900 dark:text-amber-300">LET’S PLEDGE</h3>
          <div className="flag font-serif text-center mx-auto pt-6 backdrop-blur-2xl bg-white " >
           
           <h3 className="  font-block dark:text-black"> TOWARDS NATION</h3>
           <p className="font-bold md:text-2xl sm:text-2 md:p-10 sm:p-1 dark:text-black">India is my country and all Indians are my brothers and sisters. I love my country and I am proud of its rich and varied heritage. I shall always strive to be worthy of it</p>
           <p className="font-bold md:text-2xl sm:text-2 md:p-10 sm:p-1 dark:text-black"> I shall give respect to my parents, teachers and elders and treat everyone with courtesy.</p>
           <p className="font-bold md:text-2xl sm:text-2 md:p-10 sm:p-1 pb-3 dark:text-black"> To my country and my people, I pledge my devotion. In their well being and prosperity alone, lies my happiness. </p>
          </div>
          </div>
          </div>

          
          <h3 className="text-center font-serif font-medium pb-8 text-sky-900 dark:text-amber-300">OUR ETHICS</h3>
          <p className="font-serif text-center font-bold w-7/12 mx-auto italic pb-5">You can have professional ethics, but you seldom hear about professional morals. Ethics tend to be codified into a formal system or set of rules which are explicitly adopted by a group of people. Thus you have medical ethics. Ethics are thus internally defined and adopted, whilst morals tend to be externally imposed on other people.</p>

          <div className="grid md:grid-cols-2 grid-cols-1 py-6">
            <div className="">
            
              <h3 className="font-serif text-center font-medium py-6 text-sky-900 dark:text-amber-300">VALUES</h3>
              <p className="font-serif w-7/12 text-center mx-auto">Values are the rules by which we make decisions about right and wrong, should and shouldn't, good and bad. They also tell us which are more or less important, which is useful when we have to trade off meeting one value over another.</p>
            </div>
            <div className="">
            
             <h3 className="font-serif text-center font-medium py-6 text-sky-900 dark:text-amber-300">MORALS</h3>
              <p className="font-serif w-7/12 text-center mx-auto">Morals have a greater social element to values and tend to have a very broad acceptance. Morals are far more about good and bad than other values. We thus judge others more strongly on morals than values. A person can be described as immoral, yet there is no word for them not following values.</p>
            </div>
          </div>

          <div className=" border-y-2 border-black py-8 dark:border-green-300">
            
            <h3 className="font-serif text-center font-medium text-sky-900 dark:text-amber-300">MORE OPTIONS</h3>
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="mx-auto"> 
                <ul className="uppercase font-serif font-semibold leading-loose list-disc  text-xl">
                  
                  <li> <a href="#" > overview</a></li>
                  <li><a href="#"> know your college</a></li>
                  <li><a href="#"> know your college admission</a></li>
                  <li><a href="https://scholarships.gov.in/"> scholarships </a></li>
                  <li><a href="https://www.vidyalakshmi.co.in/Students/login#studentlogin"> educational loans</a></li>
                  <li><a href="#"> research funds</a></li>
                  <li><a href="#"> placements</a></li>
                </ul>
              </div>
              <div className=" mx-auto">
                <ul className="font-serif uppercase font-semibold leading-loose list-disc text-xl">
                  
                  <li><a href="#"> e-resource</a></li>
                  <li><a href="https://www.aicte-india.org/sites/default/files/Design%20your%20Solar%20Systems%20for%20Homes.pdf"> innovative ideas</a></li>
                  <li><a href="#"> useful documents</a></li>
                  <li><a href="http://mhrdnats.gov.in/"> nats</a></li>
                  <li><a href="#"> facilities for girls</a></li>
                  <li><a href="https://www.pmrf.in/"> Prime Minister's Research Fellowship</a></li>
                  <li><a href="https://www.aicte-india.org/sites/default/files/Energy%20Literacy%20Training.pdf"> Energy Literacy Training</a></li>
                </ul>
              </div>
            </div>
          </div>
        <Footer/>
    </div>
  )
}

export default Opportunities