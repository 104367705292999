import React from "react";
import './Bureaus.css'
  
// Importing Components
import {Nav2, Footer} from '../../components';

// Importing Header
import {Header1} from '../../components';

import img1 from "../../assets/bureaus/img1.svg"
import img2 from "../../assets/bureaus/img2.svg"
import img3 from "../../assets/bureaus/img3.svg"
import img4 from "../../assets/bureaus/img4.svg"

const bureausPage = () => {
  return (
    <div className="bulletin_main_head bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2/>
      <Header1 name="BUREAUS" quote="The organization structure of the AICTE comprises of the following bureaus"/>


      <div className="grid md:grid-cols-3 sm:grid-cols-1 pt-14">
        <div className=" col-span-1 text-center font-medium mx-8">
          <div >
            <h3 className='font-serif dark:text-amber-300'>BUREAUS</h3>
          </div>

          <div className="greenlin text-center px-2">
           <ul className='pb-3'>
             <li>POLICY & ACADEMIC PLANNING</li>
             <li>APPROVAL</li>
             <li>ADMINISTRATION</li>
             <li>FINANCE</li>
             <li>CELLS</li>
           </ul>
          </div>
        </div>
        <div className="col-span-2 flex flex-col">

          <div className="grid md:grid-cols-3 grid-cols-1  text-center flex flex-row pb-20">
            <img className="w-full my-auto" src={img1} alt="" />
            <div className="col-span-2 brbox text-white">
              <h5 className="mt-5 dark:text-amber-300">APPROVAL</h5>
              <p className="mt-4">Prof. Rajendra B Kakde <br /> Adviser -I</p>
              
              <p className="mt-8">The Approvals Bureau processes proposals for grant of approval for <br /> establishing a new technical institution or an integrated campus.</p>
              <div>
                <button className='w-auto bg-transparent border-2 px-3 py-2 m-1 my-8'>Read More</button>
              </div>
            </div>
          </div>


          <div className="grid md:grid-cols-3 grid-cols-1  text-center flex flex-row pb-20">
            
            <div className="col-span-2 order-2 md:order-1 brbox text-white">
              <h5 className="mt-5 dark:text-amber-300">POLICY & ACADEMIC PLANNING</h5>
              <p className="mt-4">Dr Ramesh Unnikrishnan <br /> Adviser -II</p>
              
              <p className="mt-8">Special Cell for Research & Coordination with State Govt./UTs. and others coordinates and integrates development of technical education.</p>
              <div>
                <button className='w-auto bg-transparent border-2 px-3 py-2 m-1 my-8'>Read More</button>
              </div>
            </div>
            <div className="order-1 md:order-2 my-auto">
              <img className="col-start w-full" src={img2} alt="" />
            </div>
          </div>

          <div className="grid md:grid-cols-3 grid-cols-1  text-center flex flex-row pb-20">
            <img className="w-full my-auto" src={img3} alt="" />
            <div className="col-span-2 brbox text-white">
              <h5 className="mt-5 dark:text-amber-300">ADMINISTRATION</h5>
              <p className="mt-4">Smt Gujju Manushree<br /> Director</p>
              
              <p className="mt-8">The Administration Bureau nominates AICTE representatives on various bodies and committees, along with providing logistic support for conducting meetings and trainings for all the staff of the Council.</p>
              <div>
                <button className='w-auto bg-transparent border-2 px-3 py-2 m-1 my-8'>Read More</button>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 grid-cols-1  text-center flex flex-row pb-20">
            
            <div className="col-span-2 order-2 md:order-1 brbox text-white">
              <h5 className="mt-5 dark:text-amber-300">FINANCE</h5>
              <p className="mt-4">Sh. Ravinder Singh <br /> Director</p>
              
              <p className="mt-8">The Finance Bureau owns drawing and disbursement of planned and non-planned accounts, along with the management of cash and bills.</p>
              <div>
                <button className='w-auto bg-transparent border-2 px-3 py-2 m-1 my-8'>Read More</button>
              </div>
            </div>
            <div className="order-1 md:order-2 my-auto">
              <img className="w-full my-auto" src={img4} alt="" />
            </div>
          </div>
          
        </div>



      </div>

      <div  className="border-8 border-black w-10/12 mx-auto my-5 p-7">
        <h3 className="text-6xl md:text-left text-center mb-5 dark:text-amber-300">AICTE CELLS</h3>
        <div className="grid md:grid-cols-3 md:text-left grid-cols-1 text-center">
          <div className="">
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/atal">ATAL Academy</a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/jk"> PMSSS (for J & K Students) </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/swayam"> SWAYAM  </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/Vigilance"> Vigilance </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/parliament"> Parliament </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/fdc"> Faculty Development </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/stdc"> Student’s Development </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/media"> Media</a> </p>
          </div>

          <div className="">
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/e-governance"> E-Governance </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://mic.gov.in/mic/"> Innovation </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/grievance-redressal"> Grievance Redressal </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/estate-management"> Estate Management </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/internal-audit"> Internal Audit </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/ipc"> Induction Programs  </a> </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://iksindia.org/"> Indian Knowledge Systems (IKS) </a>  </p>
          </div>

          <div className="">
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/neat"> NEAT </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/skill-development"> Skill Development </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/legal"> Legal  </a>   </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/RTI"> RTI  </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/hindi"> Hindi  </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/idc"> Institutional Development </a>  </p>
            <p className="text-xl py-4 md:mr-10 border-b-2 border-black"> <a href="https://www.aicte-india.org/bureaus/margdarshak"> Margdarshak </a>  </p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};
  
export default bureausPage;