import React from "react";
import Image from 'react-image-webp';

import './Initiatives.css'

// Importing Components
import {Nav2, Footer} from '../../components';

// Importing Header
import {Header1} from '../../components';

// Initiatives
const Initiatives = () => {
  return (
    <div className="initiative_main_head bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2 />
      <Header1 name="INITIATIVES" quote="Read about the initiatives undertaken by AICTE to promote technical education"/>

      <div className="initiative_main_head grid md:grid-cols-4 py-5">
        <div className=" md:col-span-1 col-span-4 text-center font-medium mx-8">
          <div >
            <h3 className='font-serif dark:text-amber-300'>INITIATIVES</h3>
          </div>
          <div className="greenlin text-center px-2">
           <ul className='pb-3'>
             <li>OVERVIEW</li>
             <li>CLEAN & SMART CAMPUS AWARD</li>
             <li>ONE STUDENT ONE TREE</li>
             <li>SMART INDIA HACKATHON</li>
             <li>START UP CONTEST</li>
             <li>STUDENT LEARNING ASSESSMENT (PARAKH)</li>
             <li>AICTE VISHWKARMA AWARD 2020</li>
             <li>AICTE INTERNSHIP PORTAL</li>
             <li>NATIONAL EDUCATION ALLIANCE FOR TECHNOLOGY (NEAT)</li>
             <li>JAL SHAKTI ABHIYAN</li>
           </ul>
          </div>
        </div>
        <div className="col-span-3 grid md:grid-cols-2">
          {/* Block One */}
          <div className="text-center mx-6 my-3">
            <Image className="w-full border-b-2 border-black"
              src={require('../../assets/initiatives/Cartoon-2.jpg')}
              webp={require("../../assets/webp/Cartoon-2.webp")} alt="Cartoon"
              />
            <div className="mx-4">
              <h4 className="font-serif dark:text-amber-300">AICTE STUDENT LEARNING ASSESSMENT PROJECT</h4>
              <p>The AICTE-SLA project is designed to measure the benchmark levels and gains in academic and aptitude skills by the students in technical programs and to understand the various factors </p>
            </div>
            <button className='w-auto bg-black border-2 px-3 py-2 m-1 my-8'>Read More</button>
          </div>

          <div className="text-center mx-6 my-3 border">
            <Image className="w-full border-b-2 border-black"
              src={require("../../assets/initiatives/JalShakti.jpeg")}
              webp={require("../../assets/webp/JalShakti.webp")} alt="Cartoon"
              />
            <div  className="mx-4">
              <h4 className="font-serif dark:text-amber-300">JAL SHAKTI ABHIYANA</h4>
              <p>The scheme “Jal Shakti Abhiyan” with the theme, “Sanchay Jal Behtar Kal”, has commenced from 1st July 2019 </p>
            </div>
            <button className='w-auto bg-black border-2 px-3 py-2 m-1 my-8'>Read More</button>
          </div>
          
          <div className="text-center mx-6 my-3 border">
          <Image className="w-full border-b-2 border-black"
              src={require('../../assets/initiatives/onestudentonetree.jpeg')}
              webp={require("../../assets/webp/onestudentonetree.webp")} alt="Cartoon"
              />
            <div className="mx-4">
              <h4 className="font-serif dark:text-amber-300">ONE STUDENT - ONE TREE</h4>
              <p>Hon’ble HRD Minister Dr. Ramesh Pokhriyal “Nishank” launched a campaign “One Student One Tree” on 20th July. This initiative is in line with the Hon’ble Prime Minister’s idea of a green and healthy environment</p>
            </div>
              <button className='w-auto bg-black border-2 px-3 py-2 m-1 my-8'>Read More</button>
          </div>
          
          <div className="text-center mx-6 my-3 border">
            <Image className="w-full border-b-2 border-black"
              src={require('../../assets/initiatives/ukieri poster_revised_1.jpg')}
              webp={require("../../assets/webp/ukieri poster_revised_1.webp")} alt="Cartoon"
              />
            <div  className="mx-4">
              <h4 className="font-serif dark:text-amber-300">AICTE-UKEIRI 2020-21</h4>
              <p>UK India Education Research Initiative (UKIERI) was started in April 2006 with the aim of enhancing educational links between India and the United Kingdom.</p>
            </div>
            <button className='w-auto bg-black border-2 px-3 py-2 m-1 my-8'>Read More</button>
          </div>
        </div>
      </div>

      <div className="mx-7 mt-10 border p-5">
        <h3 className="underline font-serif mb-8 dark:text-amber-300">News & Announcements</h3>
        <div className="border-b-2">
          <h6 className="font-medium my-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum iste dignissimos at ea culpa quo cumque soluta cum eveniet expedita obcaecati eos, dolor repellendus temporibus voluptatibus minus earum voluptate atque possimus quae saepe? Possimus, pariatur harum eveniet ut delectus sint debitis explicabo autem ipsa dignissimos unde accusantium omnis aliquid optio?</h6>
          <p className="mb-2">24 April, 2020</p>
        </div>
        <div className="border-b-2">
          <h6 className="font-medium my-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum iste dignissimos at ea culpa quo cumque soluta cum eveniet expedita obcaecati eos, dolor repellendus temporibus voluptatibus minus earum voluptate atque possimus quae saepe? Possimus, pariatur harum eveniet ut delectus sint debitis explicabo autem ipsa dignissimos unde accusantium omnis aliquid optio?</h6>
          <p className="mb-2">24 April, 2020</p>
        </div>
        <div className="border-b-2">
          <h6 className="font-medium my-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum iste dignissimos at ea culpa quo cumque soluta cum eveniet expedita obcaecati eos, dolor repellendus temporibus voluptatibus minus earum voluptate atque possimus quae saepe? Possimus, pariatur harum eveniet ut delectus sint debitis explicabo autem ipsa dignissimos unde accusantium omnis aliquid optio?</h6>
          <p className="mb-2">24 April, 2020</p>
        </div>
        <div className="border-b-2">
          <h6 className="font-medium my-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum iste dignissimos at ea culpa quo cumque soluta cum eveniet expedita obcaecati eos, dolor repellendus temporibus voluptatibus minus earum voluptate atque possimus quae saepe? Possimus, pariatur harum eveniet ut delectus sint debitis explicabo autem ipsa dignissimos unde accusantium omnis aliquid optio?</h6>
          <p className="mb-2">24 April, 2020</p>
        </div>
        <button className='w-auto bg-black border-2 px-3 py-2 m-1 my-8'>Read More</button>
      </div>
      <Footer />

    </div>
  );
};
  
export default Initiatives;