import React from 'react';
import '../index.css'

function pageHeader1 (title) {
  return (
    <div className="pageHeader_main bg-hero-pattern bg-blend-overlay content-center pt-20 bg-gray-600">
      <div className='grid md:grid-cols-2 grid-cols-1 gap-2 items-center px-3 h-100 text-white'>
        <div className='text-left'>
          <h2 className='font-serif'>{title.name}</h2>
          <p>Place for the Breadcrumbs</p>
        </div>
        <div className='italic md:text-right md:pl-4 sm:py-4 font-medium '>
          <p className='text-xl'>{title.quote}</p>
        </div>
      </div>
    </div>
  )
}

export default pageHeader1