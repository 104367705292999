// Main Dependancies
import React from 'react'
import Image from 'react-image-webp';

// Importing Components
import {Nav2, Footer, Announcement, Circulars, Quicklinks, YoutubeEmbed, HomepageSlider, Sidemenu} from '../../components';

// Stylesheets
import './Home.css'


// Main Rendering-Processor
const Home = () => {
  return (
    <main>
      <Nav2 className="absolute" />
      <section className='grid grid-cols-1 md:grid-cols-2 bg-home-pattern md:px-10 bg-cover bg-center bg-no-repeat md:h-fit md:top-2'>
        <div className='md:p-5 my-auto'>
            <h1 className="text-white text-center">Welcome to the AICTE</h1>
            <h3 className='font-hindi text-white text-center'>|| योग: कर्मसु कौशलम् ||</h3>
            <p className='text-white text-center'>All India Council for Technical Education (AICTE) was set up in November 1945 as a national-level Apex Advisory Body to conduct a survey on the facilities available for technical education and to promote development in the country in a coordinated and integrated manner.</p>
        </div>
        <div className='md:p-10 md:my-5'>
        <Image className="w-full border-b-2 border-black my-3 rounded-lg"
              src={require('../../assets/aicte4_cpy.jpg')}
              webp={require("../../assets/webp/aicte4_cpy.webp")} alt="AICTE"
              />
        </div>
      </section>

      {/* Links Section */}
      <div className='grid md:grid-cols-3 grid-cols-1 gap-5 mx-5'>
        <div className='bg-home-teal dark:bg-slate-6000 p-2'>
          <Announcement />
        </div>
        <div className="bg-home-teal dark:bg-slate-6000 p-2">
          <Quicklinks />
        </div>
        <div className="bg-home-teal dark:bg-slate-6000 p-2">
          <Circulars />
        </div>
      </div>

      {/* Slider & YouTube Event */}
      <div className='grid md:grid-cols-2 grid-cols-1 gap-5 my-5 mx-2'>
        {/* Slider */}
        <div>
          <h3>Important</h3>
          <HomepageSlider className="h-full" />
        </div>
        {/* YouTube Event */}
        <div className="mx-5">
          <h3>Events</h3>
          <YoutubeEmbed embedId='oG6nG1hEHX8'/>
        </div>
      </div>
      <Sidemenu name="adsrssrs"
      />
      <Footer />
    </main>
  );
}


export default Home