// Importing React Libraries
import React from 'react'

// Importing CSS
import './footer.css' 

// Importing Images
import twitter from '../../assets/footer/Twitter_Negative.svg';
import facebook from '../../assets/footer/Facebook_Negative.svg';

// Links
import { ExternalLink } from 'react-external-link';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
    <div className='bg-black text-white pb-5'>
      <div className="grid grid-cols-1 md:grid-cols-4 p-10 font-normal text-center">

        {/* ------ BLOCK1 ------ */}
        <div className="md:border-r-2 pb-5 dark:border-green-300">
          <p className="x">
            <ExternalLink href="/">Terms of Use</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">Privacy Policy</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">Right to Information</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">Contact Us</ExternalLink>
          </p>
        </div>

        {/* ------ BLOCK2 ------ */}

        <div className="md:border-r-2 pb-5 dark:border-green-300">
          <p className="x">
            <ExternalLink href="/">Reports</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">Reports by Institutes</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">AICTE Dashboard</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">Login to Web Portal</ExternalLink>
          </p>
        </div>

        {/* ------ BLOCK3 ------ */}
        <div className="md:border-r-2 pb-5 dark:border-green-300">
          <p className="x">
            <ExternalLink href="https://facilities.aicte-india.org/pgdm/" rel='noreferrer' target="_blank" >PGDM Student Verification</ExternalLink>
          </p>
          <p className="x">
            <ExternalLink href="/">Verify Institute Approval</ExternalLink>
          </p>
          <p className="x">
            <ExternalLink href="/">Centralized Support System</ExternalLink>
          </p>
        </div>

        {/* ------ BLOCK4 ------ */}

        <div className="pb-0">
          <p className="x">
            <ExternalLink href="https://facilities.aicte-india.org/pgdm/" rel='noreferrer' target="_blank">National Scholarship</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="https://www.vidyalakshmi.co.in/Students/indexPopup" rel='noreferrer' target="_blank">Vidya Lakshmi</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="https://www.aicte-india.org/schemes/students-development-schemes" rel='noreferrer' target='_blank'>Pragati and Saksham</ExternalLink>
          </p>

          <p className="x">
            <ExternalLink href="/">India Innovation Initiative</ExternalLink>
          </p>
        </div>
      </div>
      <div className='text-center content-center '>
        <span className='font-serif'>Copyright&copy;2017.AICTE.</span>
        <p className="font-serif">Nelson Mandela Marg, Vasant Kunj, New Delhi -110070</p>
        <div className="footer_social_icons">
          <img src={facebook} alt="Facebook" className="px-2 my-2"/>
          <img src={twitter} alt="Twitter" className="px-2 my-2"/>
        </div>
      </div>
    </div>
    </footer>
  )
}

export default Footer