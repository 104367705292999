// Import React
import { render } from "@testing-library/react";
import React from "react";

const News = () => {
    render(
        <div>
            News
        </div>
    )
}

export default News