import React from "react";
import './gw.css'

// Importing Images
import Timage from "../../assets/topic/topic1.svg"

// Importing Components
import { Nav2, Header2, Footer } from '../../components'


const GW = () => {
  return (
    <div className="bg-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl dark:text-white'">
        <Nav2/>
        <Header2 name="Topic Page" quote="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."/>

        <div className='Tmain '>
            <h2 className='font-serif text-center py-6 font-semibold dark:text-amber-300'>Topic</h2>
            <p className=' font-semibold text-xl italic m-2 text-center  px-12 py-8 dark:text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>

            <div className="grid md:grid-cols-3 grid-cols-1 px-8">
                <div className="box-border h-30 w-38 p-5 ">
                    <img  src={Timage}  alt="" />
                </div>
                <div className="box-border h-30 w-38 p-5 ">
                <img src={Timage} alt="" />
                </div>
                <div className="box-border h-30 w-38 p-5 ">
                <img src={Timage} alt="" />
                </div>
            </div>
            {/* <hr> </hr> */}
        </div>

        <div className="border-t-2 border-black dark:border-green-300 pb-2 my-12 "></div>
            <h2 className="font-serif font-semibold text-center dark:text-amber-300">SubTopics</h2>
            
            <div className="grid md:grid-cols-2 grid-cols-1 m-2 ">
                <div className=" font-serif col-span-1 mx-auto dark:text-green-300">
            <ul className="ada1  leading-loose text-5xl ">
                <li>OVERVIEW</li>
                <li>KNOW YOUR COLLEGE</li>
                <li>ADMISSIONS</li>
            </ul>
            </div>
                <div className="font-serif col-snap-2 mx-auto">
            <ul className="ada2 leading-loose text-4xl text-medium dark:text-green-300 ">
                <li>E-RESOURCE</li>
                <li>INNOVATIVE IDEAS</li>
                <li>USEFUL DOCUMENTS</li>
            </ul>
            </div>
            </div>
            <div className="pad1 "></div>
            <div className="px-14">
            <h4 className="font-serif m-2 text-black ml-24 font-semibold md:mx-auto dark:text-amber-300">OVERVIEW</h4>
            <div className="border-t-2 border-black dark:border-green-300 pb-2  mx-auto"></div>
            <p className=" py-10 text-center dark:text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>
            
            <div className="pad2"></div>

            <h4 className="font-serif m-2 ml-24 font-semibold md:mx-auto dark:text-amber-300">E-RESOURCE</h4>
            <div className="border-t-2 border-black dark:border-green-300 pb-2  mx-auto"></div>
            <p className="text-center  py-10 dark:text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>

            <div className="pad2"></div>

            <h4 className="font-serif m-2 ml-24 font-semibold md:mx-auto dark:text-amber-300">INNOVATIVE IDEAS</h4>
            <div className="border-t-2 border-black dark:border-green-300 pb-2  mx-auto"></div>
            <p className="text-center  py-6 dark:text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>

            <div className="pad2"></div>

            <h4 className="font-serif m-2 ml-24 font-semibold md:mx-auto dark:text-amber-300">ADMISSIONS</h4>
            <div className="border-t-2 border-black dark:border-green-300 pb-2  mx-auto"></div>
            <p className="text-center  py-6 dark:text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>

            <div className="pad2"></div>

            <h4 className="font-serif m-2 ml-24 font-semibold md:mx-auto dark:text-amber-300">USEFUL DOCUMENTS</h4>
            <div className="border-t-2 border-black dark:border-green-300 pb-2  mx-auto"></div>
            <p className="text-center  py-10 dark:text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>

            <div className="pad2"></div>
            </div>








        <Footer/>
    </div>
  );
};
  
export default GW;