import React from 'react'
import './Education.css'

import image13 from "../../assets/education/aicte2.png"
import logo from "../../assets/education/home_logo.svg"

// Importing Components
import {Nav2, Footer} from '../../components';

// Importing Header
import {Header2} from '../../components';

const Education = () => {
  return (
    <div className="bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2 />
      <Header2 name="Education" quote="Education or teaching in the broadest sense is any act or experience that has a formative effect on the mind, character or physical ability of an individual. In its technical sense,  education is the process by which society deliberately transmits its accumulated knowledge, skills and values from one generation to another."/>
      <div className="grid md:grid-cols-4 grid-cols-1">
      <div className="col-span-1 px-6">
          <div className="font-serif text-center py-8 font-bold ">
            <h3 className='text-5xl text-w dark:text-amber-300'>EDUCATION</h3>
          </div>

          <div className="edu text-center ">
           <ul>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#"> OVERVIEW</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#"> COMMUNITY COLLEGES</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#">IT & ICT IN EDUCATION</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#">COLLABORATIONS (MoUs)</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#">DISTANCE EDUCATION</a></li>
             <li className="my-3 border-b-2 border-black dark:border-green-300 pb-2"><a href="#">KEY EDUCATION</a></li>
           </ul>
          </div>
        </div>

        <div className="col-span-3 py-8">
          <h3 className="font-serif text-center pb-5 font-medium underline underline-offset-1 dark:text-amber-300">Brief Introduction</h3>
          <p className="pb-8 px-8">Teachers in educational institutions direct the education of students and might draw on many subjects, including reading, writing, mathematics, science and history. This process is sometimes called schooling when referring to the education of teaching only a certain subject, usually as professors at institutions of higher learning. There is also education in fields for those who want specific vocational skills, such as those required to be a pilot. In addition there is an array of education possible at the informal level, such as in museums and libraries, with the Internet and in life experience. Many non-traditional education options are now available and continue to evolve.</p>
        
          <h3 className="font-serif text-center pb-5 font-medium underline underline-offset-1 dark:text-amber-300">The Right to Education</h3>
          <p className="pb-8 px-8">The Right to Education has been established as a basic human right: since 1952, Article 2 of the first Protocol to the European Convention on Human Rights obliges all signatory parties to guarantee the right to education. At world level, the United Nations' International Covenant on Economic, Social and Cultural Rights of 1966 guarantees this right under its Article 13.</p>

          <p className="font-bold px-8 dark:text-green-300"> India has passed the Right to Education Bill 2009 in August 2009.</p>
        </div>
      </div>


      <div className=" grid md:grid-cols-3 grid-cols-1 mt-12 px-2 py-7">
        <div className="border-r-2 border-black pt-4 dark:border-green-300">
          <h6 className="font-serif font-medium text-center pb-4 dark:text-amber-300">Eleventh Five Year Plan Education</h6>
          <p className="text-center px-6 font-sans pb-5 ">The role of education in facilitating social and economic progress is well recognized. It opens up opportunities leading to both individual and group entitlements.</p>
          <div className="pt-5 flex items-center justify-center">
            <button className="w-auto bg-transparent border-2 px-3 py-2 mb-2 dark:text-white  text-black border-black ">Read More</button>
        </div>
        </div>
        <div className="border-r-2 border-black pt-4 dark:border-green-300">
          <h6 className="font-serif font-medium text-center pb-4 dark:text-amber-300">Engendering XI Five Year Plan</h6>
          <p className="text-center px-6  font-sans pb-5 ">Engendering XI Five Year Plan (below to be added as description) A civil society initiative coordinated by National Alliance of Women (NAWO) with support from Ministry of Women and Child Development (MWCD), UN Development Program (UNDP) and UN Development Fund for Women (UNIFEM).</p>
          <div className="pt-5 flex items-center justify-center">
            <button className="w-auto bg-transparent border-2 px-3 py-2 mb-2 dark:text-white  text-black border-black">Read More</button>
        </div></div>
        <div className='pt-4'>
          <h6 className="font-serif font-medium text-center pb-4 dark:text-amber-300">Higher Education XI Plan</h6>
          <p className="text-center px-6 font-sans">A little more than half a century has passed since the government initiated a planned development of higher education in the country with the establishment of University Grants Commission in 1953.</p>
          <div className="pt-5 flex items-center justify-center"><button className="w-auto bg-transparent border-2 px-3 py-2 mb-2 dark:text-white  text-black border-black">Read More</button>
        </div></div>
      </div>

        
      






      <Footer />

    </div>
  )
}

export default Education