// Dark Mode in React
// Source: https://www.youtube.com/watch?v=znU2RD0vGJY
// Github: https://github.com/ishan-me/tailwind_darkmode/blob/main/src/components/toggle.js

// Importing React
import React from "react";

const GetInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedPrefs = window.localStorage.getItem('current-theme');
      if (typeof storedPrefs === 'string') {
        return storedPrefs;
      }
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'light';
      }
    }
    return 'light';
  };
  
export const ThemeContext = React.createContext();
  
export const ThemeProvider = ({ initialTheme, children }) => {
    const [theme, setTheme] = React.useState(GetInitialTheme);
  
    const checkTheme = (existing) => {
      const root = window.document.documentElement;
      const isDark = existing === 'dark';
  
      root.classList.remove(isDark ? 'light' : 'dark');
      root.classList.add(existing);
  
      localStorage.setItem('current-theme', existing);
    };
  
    if (initialTheme) {
      checkTheme(initialTheme);
    }
  
    React.useEffect(() => {
      checkTheme(theme);
    }, [theme]);
  
    return (
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {children}
      </ThemeContext.Provider>
    );
};