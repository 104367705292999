// Import Libraires
import React from "react";
import "./Quicklinks.css";

// Icons
import bookmark_dark from '../../assets/icons/Bookmark.svg';
import bookmark_white from '../../assets/icons/Bookmark-white.svg';

// External links
import { ExternalLink } from 'react-external-link';

function Quicklinks() {
  const [ann_text] = React.useState([
    {
      text: "Student Learning Assessment-PARAKH",
      qLinks: "https://parakh.aicte-india.org/",
    },
    {
      text: "AICTE-AI Language Translation Tool",
      qLinks: "https://free.aicte-india.org/",
    },
    {
      text: "Approval Process 2022-23",
      qLinks: "https://www.aicte-india.org/ap2023",
    },
    {
      text: "ODL and Online Regulatory Framework",
      qLinks: "https://aicte-india.org/odl/",
    },
    {
      text: "PMSSS J&K",
      qLinks: "https://www.aicte-india.org/bureaus/jk",
    },
    {
      text: "Academic Leaders' Conclave",
      qLinks: "https://www.aicte-india.org/alc",
    },
   
  ]);

  function Quickl({ todo }) {
    return (
        <div className="bg-white m-2">
            <div className="flex p-2 m-2 gap-3">
                <img src={bookmark_dark} alt="img tag"/>
                <ExternalLink href={todo.qLinks}><span className="inline-block">{todo.text}</span></ExternalLink>
            </div>
        </div>

    );
  }
  return (
    <div>
        <h3 className="capitalize text-center dark:text-amber-300 mt-1">quicklinks</h3>
        <div className="p-2">
            {ann_text.map((todo, index, qLinks) => (
            <Quickl
                key={index}
                index={index}
                todo={todo}
                xlink={qLinks}
            />
            ))}
        </div>
    </div>
  );
}

export default Quicklinks;