import React from "react";
import "./Announcement.css";

// Icons
import flag_dark from '../../assets/icons/Flag.svg';
import flag_white from '../../assets/icons/Flag-white.svg';

// React Router
import { Route, Link } from "react-router-dom";
import { ExternalLink } from 'react-external-link';

function Announcement() {
  const [ann_text] = React.useState([
    {
      text: "Public Notice for Approval Process 2022-23",
      acc_links: "https://www.aicte-india.org/sites/default/files/Public%20Notice%20for%20website%20English%20and%20Hindi%20%281%29%20%281%29.pdf"
    },
    {
      text: "Academic Calendar 2022-23",
      acc_links: "https://www.aicte-india.org/sites/default/files/Revised%20Ciruclar%20Academic%20Year%202022-23.pdf"
    },
    {
      text: "AICTE Calendar-2022",
      acc_links: "https://www.aicte-india.org/sites/default/files/AICTE_Cal_2022_Final_31.12.2021_compressed_0.pdf"
    },
    {
      text: "Advertisement for the post of Consultant (Retired) In AICTE",
      acc_links: "https://www.aicte-india.org/sites/default/files/Advt-Consultant.pdf"
    },
    {
      text: "Fulbright-Nehru and other Fulbright Fellowships for study, research, teaching, and professional development in the U.S 2023-2024",
      acc_links: "https://aicte-india.org/sites/default/files/Circular%20dated%2024.03.2022.pdf"
    },
    {
      text: "Approval Process Handbook 2022-23",
      acc_links: "https://www.aicte-india.org/sites/default/files/approval/2022-23/Approval%20Process%20Handbook2022-23.pdf"
    },
    
  ]);

  function Announce({ todo, index }) {
    return (
        <div className="bg-white m-2">
            <div className="flex p-2 m-2 gap-3">
                <img src={flag_dark} alt="img tag"/>
                <ExternalLink href={todo.acc_links}><span className="inline-block">{todo.text}</span></ExternalLink>
            </div>
        </div>
    );
  }
  return (
    <div>
        <h3 className="capitalize text-center dark:text-amber-300 mt-1">announcements</h3>
        <div className="p-2">
            {ann_text.map((todo, index) => (
            <Announce
                key={index}
                index={index}
                todo={todo}
            />
            ))}
        </div>
    </div>
  );
}

export default Announcement;