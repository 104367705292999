import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import imt from '../../assets/navit.png'

const AnyReactComponent = ({ text }) =>  <div className="font-sans text-sm font-bold text-black-600 text-center"><img src={imt} alt="" />{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 23.47 ,
      lng: 79.15
    },
    zoom:5,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDCFz4Hqk6Irpoin90dyNxXNBAw7D1H5kY' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
          <AnyReactComponent
            lat={19.138330 }
            lng={72.899078}
            text="AICTE Office- MUMBAI"
          />
          <AnyReactComponent
            lat={13.067919 }
            lng={80.243805}
            text="AICTE Office- CHENNAI"
          />
          <AnyReactComponent
            lat={12.974284}
            lng={77.581726}
            text="AICTE Office- BENGALURU"
          />
          <AnyReactComponent
            lat={23.30189285262959  }
            lng={77.36029548956637}
            text="AICTE Office- BHOPAL(MP)"
          />
          <AnyReactComponent
            lat={22.31558369370661  }
            lng={73.187331943235}
            text="AICTE Office- GUJARAT"
          />
          <AnyReactComponent
            lat={30.73883677556582  }
            lng={76.7582374768663}
            text="AICTE Office- CHANDIGARH"
          />
            <AnyReactComponent
              lat={26.497164084074946  }
              lng={80.2892225752758}
              text="AICTE Office- KANPUR (UP)"
            />
          <AnyReactComponent
            lat={22.561036838077314  }
            lng={88.41108411262054}
            text="AICTE Office- KOLKATA (WB)"
          />
          <AnyReactComponent
            lat={17.438654614066426  }
            lng={78.4479865652167 }
            text="AICTE Office- HYDERABAD (TELANGANA)"
          />
          <AnyReactComponent
            lat={26.143196864765915  }
            lng={91.66805511306606}
            text="AICTE Office- GUWAHATI (ASSAM)"
          />
          <AnyReactComponent
            lat={8.545932248347658   }
            lng={76.90633916148242}
            text="College of Engineering Trivandrum"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;