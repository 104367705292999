import React from 'react'
import './P_release.css'
import datebox from '../../assets/datebox.svg'

const PressRelease = () => {
  return (
    <div className='pb-2 container'>
      <h2 className='font-serif dark:text-amber-300'>PRESS RELEASE</h2>
      <div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="cirbox1">
          <div className="datebox1">
            <img src={datebox} alt="" />
          </div>
          <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
      <div className='justify-items-end	self-end'>
        <button className='w-auto px-4 py-2 m-1 '>Read More</button>
      </div>
    </div>

  )
}

export default PressRelease