import React from 'react'
import './initiatives.css'
import aicte2 from '../../../assets/aicte2.png'
import indianflag from '../../../assets/indian_flag_bg.png'
import arrowright from '../../../assets/homepage/arrow-right.svg'
import scholarship from '../../../assets/national_scholarship.png'
import vidyalaxmi from '../../../assets/Vidya_laxmi.png'
import pragati from '../../../assets/pragati_and_saksham.png'
import innovative from '../../../assets/Innovation_initiative.png'
import datebox from '../../../assets/datebox.svg'

// React Icons
import {BsCaretRightFill} from 'react-icons/bs';


const initiative_sec = () => {
  return (
    <div className="main__head4">
      <div className="sub__head4">

        <div className="circulars">

          <div className="circulars_heading">
            <h2>Circulars</h2>
          </div>

          <div className="circulardiv">
            <div className="cirbox">
              <div className="datebox">
                <img src={datebox} alt="" />
                <div className="bignumber"></div>
                <div className="smalldetails"></div>
              </div>
              <div className="circularheadings">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe, facere facilis molestiae quasi est vitae?</div>
            </div>
            <div className="cirbox">
              <div className="datebox">
                <img src={datebox} alt="" />
              </div>
              <div className="circularheadings">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error officia magnam veniam commodi, a aliquam!</div>
            </div>
            <div className="cirbox">
              <div className="datebox">
                <img src={datebox} alt="" />
              </div>
              <div className="circularheadings">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi tenetur velit blanditiis eveniet ducimus sequi.</div>
            </div>
            <div className="cirbox">
              <div className="datebox">
                <img src={datebox} alt="" />
              </div>
              <div className="circularheadings">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor facere hic impedit rem, in vero?</div>
            </div>
          </div>

        </div>


        <div className="initiative">
          <div className="initiative_heading">
              <h2>Initiatives & Schemes</h2>
          </div>
          <div className="flagboxes">
            
            <div className="flagbox1">
              <p className="flagbox_heading">Prime Minister's Special Scholarship Scheme <br /> (PMSSS)</p>
              
              <p className="flagbox_para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, optio! Quaerat porro repellendus magnam necessitatibus, quas dolorem animi dolorum corporis voluptate neque doloribus ipsum qui error eligendi aliquam aspernatur tempore! Odio quae fuga ducimus illo laboriosam quo magnam? Ipsum quasi sed accusamus in placeat eum harum asperiores similique dolores accusantium.</p>

              <div className="inoneline">
                <p className="learnmore">Learn more</p>
                <img src={arrowright} alt="Pause Button" />
              </div>
            </div>
            <div className="flagbox2">
              <p className="flagbox_heading"> <br /> Unnat Bharat Abhiyan</p>
              
              <p className="flagbox_para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, optio! Quaerat porro repellendus magnam necessitatibus, quas dolorem animi dolorum corporis voluptate neque doloribus ipsum qui error eligendi aliquam aspernatur tempore! Odio quae fuga ducimus illo laboriosam quo magnam? Ipsum quasi sed accusamus in placeat eum harum asperiores similique dolores accusantium.</p>

              <div className="inoneline">
                <p className="learnmore">Learn more</p>
                <img src={arrowright} alt="Pause Button" />
              </div>

            </div>
          </div>
        </div>

        {/* Infomation Blocks - STARTS */}
        <div className='grid md:grid-cols-4 sm:grid-cols-2 p-2 my-5'>
          {/* National E-Scholarship */}
          <div className='border-2 border-black p-4 my-2 mx-3 bg-white h-auto drop-shadow-md hover:drop-shadow-xl'>
            <div className='flex items-start align-middle my-2'>
              <img src={scholarship} alt="Scholarship" />
              <h5 className='font-serif text-xl font-semibold mb-2 mx-2'>National e-scholarship</h5>
            </div>
            <p className='font-sm my-5 min-h-10'>National e-Scholarship is the one-stop solution to help students seek various scholarships.</p>
            <button onclick="location.href='https://google.com';" className='bg-white text-black font-bold md:py-2 flex items-center'>Learn More <BsCaretRightFill/></button>
          </div>

          {/* Vidya Lakshmi */}
          <div className='border-2 border-black p-4 my-2 mx-3 bg-white h-auto drop-shadow-md hover:drop-shadow-xl'>
            <div className='flex items-start align-middle my-2'>
              <img src={scholarship} alt="Scholarship" />
              <h5 className='font-serif text-xl font-semibold mb-2 mx-2'>Vidya Lakshmi</h5>
            </div>
            <p className='font-sm my-5'>Vidya Lakshmi is a first-of-its-kind portal for students seeking educational loan.</p>
            <button onclick="location.href='https://google.com';" className='bg-white text-black font-bold md:py-2 flex items-center'>Learn More <BsCaretRightFill/></button>
          </div>

          {/* Pragati and Saksham */}
          <div className='border-2 border-black p-4 my-2 mx-3 bg-white h-auto drop-shadow-md hover:drop-shadow-xl'>
            <div className='flex items-start align-middle my-2'>
              <img src={scholarship} alt="Scholarship" />
              <h5 className='font-serif text-xl font-semibold mb-2 mx-2'>Pragati and Saksham</h5>
            </div>
            <p className='font-sm my-5 min-h-10'>Scholarship for girls under the Pragati Scheme and scholarship for differently-abled students under Saksham Scheme.</p>
            <button onclick="location.href='https://google.com';" className='bg-white text-black font-bold md:py-2 flex items-center'>Learn More <BsCaretRightFill/></button>
          </div>

          {/* Innovation */}
          <div className='border-2 border-black p-4 my-2 mx-3 bg-white h-auto drop-shadow-md hover:drop-shadow-xl'>
            <div className='flex items-start align-middle my-2'>
              <img src={scholarship} alt="Scholarship" />
              <h5 className='font-serif text-xl font-semibold mb-2 mx-2'>India Innovation Initiative</h5>
            </div>
            <p className='font-sm my-5 min-h-10'>India's largest innovation challenges jointly promoted by the CII and Department of Science & Technology.</p>
            <a href="https://google.com/" target="_blank" rel="noreferrer">
              <button type='button' className='bg-white text-black font-bold md:py-2 flex items-center'>Learn More <BsCaretRightFill/></button>
            </a>
          </div>
          {/* Infomation Blocks - ENDS */}

        </div>
      </div>
    </div>
  )
}

export default initiative_sec