// Default Libraries
import React from 'react';
import ReactDOM from 'react-dom';

// Main CSS
import './index.css';

// Font CSS
import './fonts.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

// Tailwind Addons
import 'flowbite';
import 'tw-elements';

// Pages
import { Homepage, About, Bulletins, Bureaus, Initiatives, Newsroom, Schemes, Education, Opportunities, GWPage, Test, Home, Contact } from './pages';

// Importing React Router
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { ThemeProvider } from './components/navbar2/ThemeContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Router>
        <Routes>
          {/* App */}
          <Route exact path='/app' element={<App />} />
          {/* Home */}
          <Route exact path='/homeold' element={<Homepage />} />
          {/* HomePage */}
          <Route exact path='/' element={<Home />} />
          {/* About Us Page */}
          <Route exact path="/about" element={<About />} />
          {/* Bureaus */}
          <Route exact path='/bureaus' element={<Bureaus />} />
          {/* Initiatives */}
          <Route exact path='/initiatives' element={<Initiatives />} />
          {/* News */}
          <Route exact path='/news' element={<Newsroom />} />
          {/* Education */}
          <Route exact path='/education' element={<Education />} />
          {/* Bulletins */}
          <Route exact path='/bulletins' element={<Bulletins />} />
          {/* Schemes */}
          <Route exact path='/schemes' element={<Schemes />} />
          {/* opportunities */}
          <Route exact path='/opportunities' element={<Opportunities />} />
          {/* GW */}
          <Route exact path='/gw' element={<GWPage />} />
          {/* Contact */}
          <Route exact path='/contact' element={<Contact />} />
          {/* 404 Page  */}
          <Route path='*' element={<h1>404 Not Found</h1>} /> 
          {/* Test Page */}
          <Route path='/test' element={<Test />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
