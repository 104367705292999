import React from 'react'
import './About.css' 

// Importing Components
import {Nav2, Header2, Footer} from '../../components';

import aicte2 from '../../assets/aicte2.png'
import slantline from '../../assets/Slant_line.svg'
import vision from '../../assets/vision.svg'
import homeLogo from '../../assets/home_logo.svg'

const AboutUs = () => {
  return (
    <div className="bg-white bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2 />
      <Header2 name="About Us" quote="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."/>      
      
      <div className="grid md:grid-cols-6">
        <div></div>
        <div className="col-span-4 bg-sky-200 mt-8">
          <div className="flex flex-row grid md:grid-cols-2 lg:grid-cols-4 p-5 dark:bg-slate-900">
            <h4 className="md:border-r-2 border-b-2 md:border-b-2 lg:border-b-0 border-black text-center py-2">OVERVIEW</h4>
            <h4 className="md:border-r-2 border-b-2 md:border-b-2 lg:border-b-0 md:border-r-0 lg:border-r-2 border-black text-center py-2">OFFICES</h4>
            <h4 className="md:border-r-2 border-b-2 border-b-2 md:border-b-0 border-black text-center py-2">MESSAGE</h4>
            <h4 className="border-black border-b-2 border-b-2 md:border-b-0 text-center py-2">HISTORY</h4>
          </div>
        </div>
        <div></div>
      </div>

      <div className="grid grid-cols-10 mt-10">
        <div></div>
        <div className="col-span-8">
          <h3 className="w-full border-b-2 border-black about_blue_clr dark:text-amber-300">OVERVIEW</h3>
          <h5 className="text-center my-14 px-14 py-10">Technical education in India contributes a major share to the overall education system and plays a vital role in the social and economic development of our nation.</h5>
        </div>
      </div>

      <div className="grid grid-cols-10">
        <div></div>
        <div className="col-span-8">
          <h3 className="w-full border-b-2 border-black about_blue_clr dark:text-amber-300">The Organization</h3>
          <h5 className="px-7 py-10">In accordance with the provisions of the AICTE Act (1987), for the first five years after its inception in 1988, the Minister for Human Resource Development, the Government of India, was the Chairman of the Council. The first full-time Chairman was appointed on July 2, 1993 and the Council was reconstituted in March 1994 with a term of three years. The Executive Committee was re-constituted on July 7, 1994 and All India Board of Studies and Advisory Boards were constituted in 1994-95. Regional Offices of the Ministry of Human Resource Development, the Government of India, located in Kolkata, Chennai, Kanpur, and Mumbai were transferred to AICTE and the staff working in these offices were also deputed to the Council on foreign service terms w.e.f. October 1, 1995. <br /> <br /> These offices functioned as secretariats of regional Committees in the four regions (North, East, West and South). Three new regional Committees in southwest, central, and northwest regions with their secretariats located in Bangalore, Bhopal, and Chandigarh, respectively, were also established on July 27, 1994. One more regional committee in South-Central region with its Secretariat in Hyderabad was notified on March 8, 2007.</h5>
        </div>
      </div>

      <div>
        <h3 className="text-center mb-7 about_blue_clr dark:text-amber-300">Our Objectives</h3>
        <div className="flex flex-row grid text-center md:grid-cols-3 px-8">
          <div className="flex flex-row border-b-2 border-black md:border-b-0">
            <h5>Promotion of Quality in Technical Education.</h5>
            <img className="md:visible invisible" src={slantline} alt="" />
          </div>
          <div className="flex flex-row border-b-2 border-black md:border-b-0">
            <h5>Planning and Coordinated Development of Technical Education System.</h5>
            <img className="md:visible invisible h-full" src={slantline} alt="" />
          </div>
          <div className="border-b-2 border-black md:border-b-0">
            <h5>Regulations and Maintenance of Norms and Standards.</h5>
          </div>
        </div>
      </div>

      <div className="w-full my-8">
        <img className="justify-center mx-auto" src={vision} alt="" />
        <h4 className="text-center my-14 mx-12">“To be a world-class organization leading technological and socio-economic development of the country by enhancing the global competitiveness of technical manpower and by ensuring high quality technical education to all sections of the society.”</h4>
      </div>

      <div className="w-auto text-center m-12 border-4 border-black py-5">
        <h3 className="mb-8 about_blue_clr dark:text-amber-300">Our Mission</h3>
        <h5 className="font-bold">A true facilitator and an objective regulator</h5>
        <h5 className="font-bold"> ~ <br /> Transparent governance and accountable approach towards the society</h5>
        <h5 className="font-bold"> ~ <br /> Planned and coordinated development of Technical Education in the country by ensuring world-class standards of institutions through accreditation</h5>
        <h5 className="font-bold"> ~ <br /> Emphasis on developing high quality institutions, academic excellence, and innovative research and development programmes</h5>
        <h5 className="font-bold"> ~ <br /> Networking with/ or a network of institutions for optimum resource utilization</h5>
        <h5 className="font-bold"> ~ <br /> Dissemination of knowledge</h5>
        <h5 className="font-bold"> ~ <br /> Technology forecasting and global manpower planning</h5>
        <h5 className="font-bold"> ~ <br /> Promoting industry-institution interaction for developing new products, services, and patents</h5>
        <h5 className="font-bold"> ~ <br /> Inculcating entrepreneurship</h5>
        <h5 className="font-bold"> ~ <br /> Encouraging indigenous technology</h5>
        <h5 className="font-bold"> ~ <br /> Focusing on non-formal education</h5>
        <h5 className="font-bold"> ~ <br /> Making Technical Education in India globally acceptable</h5>
        <h5 className="font-bold"> ~ <br /> Providing affordable education to all</h5>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs