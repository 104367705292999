import React from 'react'
import './pageHeader3.css'
import aicte2 from '../../assets/aicte2.png'
import aicte3 from '../../assets/bureaus.png'

import { Header1 } from '..'

const PageHeader3 = () => {
  return (
    <div className="main__head3">
      <div className="sub__head3">
        {/* <img src={aicte2} alt="backimg"/> */}

        <div className="bureaus_heading">
          <h2>Bureaus</h2>
        </div>

        <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul class="flex flex-wrap -mb-px" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li class="mr-2" role="presentation">
                  <button class="inline-block py-4 px-4 text-sm font-medium text-center text-black-600 rounded-t-lg border-b-2 border-transparent hover:text-red-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Overview</button>
              </li>
              <li class="mr-2" role="presentation">
                  <button class="inline-block py-4 px-4 text-sm font-medium text-center text-black-600 rounded-t-lg border-b-2 border-transparent hover:text-red-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 active" id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Approval</button>
              </li>
              <li class="mr-2" role="presentation">
                  <button class="inline-block py-4 px-4 text-sm font-medium text-center text-black-600 rounded-t-lg border-b-2 border-transparent hover:text-red-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300" id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Policy & Academics</button>
              </li>
              <li class="mr-2" role="presentation">
                  <button class="inline-block py-4 px-4 text-sm font-medium text-center text-black-600 rounded-t-lg border-b-2 border-transparent hover:text-red-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300" id="contacts-tab" data-tabs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false">Administration</button>
              </li>
              <li class="mr-2" role="presentation">
                  <button class="inline-block py-4 px-4 text-sm font-medium text-center text-black-600 rounded-t-lg border-b-2 border-transparent hover:text-red-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300" id="finance-tab" data-tabs-target="#finance" type="button" role="tab" aria-controls="finance" aria-selected="false">Finance</button>
              </li>
          </ul>
        </div>
        <div id="myTabContent">
            <div class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Overview tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Approval tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
            </div>
            <div class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Policy & Academics tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
            </div>
            <div class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
                <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Administration tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
            </div>
            <div class="hidden p-4 bg-gray-50 rounded-lg dark:bg-gray-800" id="finance" role="tabpanel" aria-labelledby="contacts-tab">
                <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Finance tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
            </div>
        </div>
        {/* <Header1/> */}
        <img className="b__image" src={aicte3} alt="" />
      </div>
      

    </div>
  )
  
}

export default PageHeader3



