import React from 'react'
import './pageHeader2.css'
import aicte2 from '../../assets/aicte2.png'
import important from '../../assets/Important_crop.png'

function PageHeader2 (pages) {
  return (
    
    <div className="main__head2">
      
      <div className="sub__head2">
        {/* <img src={aicte2} /> */}
        <div className="head2__slidebar">
          <h3>Announcements</h3>
          <div className="slidebar__list1">
            
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing ayhsg asdfs atsr7qw  elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio,  </li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.astyf asytqs qwsytr  Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
          </div>
        
          <h4>Quick Links</h4>
          <div className="slidebar__list2">  
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing ayhsg asdfs atsr7qw  elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.asfdc asf sstfas tasdys  Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, illo.</li>
          </div>
        </div>
      </div>

      <div className="sub_head1">

        <div className="impodiv">
          <h2>IMPORTANT</h2>
          <img src={important} alt="Important"/>
        </div>

        <div className="eventdiv">
          <h2>EVENTS</h2>
          <iframe src="https://www.youtube.com/embed/BCF0ehfETvY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      </div>

    </div>
  )
}
 export default PageHeader2