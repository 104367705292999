// React Library
import React from 'react';
import './Contact.css';

// Importing Components
import {Nav2, Footer, ContactForm, Header1, GoogleMapReact} from '../../components';

// Links
import { ExternalLink } from 'react-external-link';



const Contact = () => {
  return (
    <div className="bg-white dark:text-white dark:bg-slate-900 rounded-lg gap-3 ring-1 ring-slate-900/5 shadow-xl">
        <Nav2 />
        <Header1 name="Contact Us" />
        <section className='grid md:grid-cols-2 p-2 my-2 mx-10'>
            <div className='pr-5'>
              <h4>AICTE HEAD OFFICE</h4>
              <hr/>
              <h5 className='mt-2 font-sans text-base'>
                Nelson Mandela Marg,<br />
                Vasant Kunj, New Delhi-110070
              </h5>
              <h5 className='font-sans mt-5 text-lg'>Phone: 011-26131576-78,80</h5>
              <h5 className='font-sans mt-5 text-lg'>Emails:</h5>
              <div>
                <p><b>Technical Queries</b> : <ExternalLink href="mailto:helpdesk1@aicte-india.org">helpdesk1@aicte-india.org</ExternalLink></p>
                <p><b>Approval Process</b> : <ExternalLink href="mailto:helpdeskab@aicte-india.org">helpdeskab@aicte-india.org</ExternalLink></p>
                <p><b>State Management</b> : <ExternalLink href="mailto:emc@aicte-india.org">emc@aicte-india.org</ExternalLink></p>
                <p><b>SWAYAM</b> : <ExternalLink href="mailto:support@swayam.gov.in">support@swayam.gov.in</ExternalLink></p>
                <p><b>Digital Signature Certificate (DSC)</b> : <ExternalLink href="mailto:helpdesk1@aicte-india.org">helpdesk1@aicte-india.org</ExternalLink></p>
              </div>
            </div>
            <div>
              <ContactForm/>
            </div>
        </section>

        {/* Locations */}
        <section className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 p-2 my-2 mx-10 gap-3">
            {/* Mumbai */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Mumbai</h4>
              <p className='py-2 px-3'>
                AICTE-Western Regional Office,<br/>
                Industrial Research Building,<br/>
                2nd floor,  National Institute of<br/> 
                Industrial Engineering (NITIE) Campus,<br/> 
                Vihar Lake, Mumbai – 400 087<br/>
              </p>
              <p className='px-3'><strong>Phone:</strong> 022-28475793, 28470019</p>
            </div>
            {/* Chennai */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Chennai</h4>
              <p className='py-2 px-3'>
                AICTE – Southern Regional Office,<br/>
                ‘Shastri Bhavan’, 26, Haddows Road,<br/>
                Nungambakkam, Chennai – 600 006
              </p>
              <p className='px-3'><strong>Phone:</strong> 044-28279998, 28275650, 28232754</p>
            </div>
            {/* Bangaluru */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Bengaluru</h4>
              <p className='py-2 px-3'>
              AICTE-South Western Regional Office,<br/>P.K. Block, Palace Road,<br/>Bangalore – 560 009

              </p>
              <p className='px-3'><strong>Phone:</strong> 080-22205979, 080-22205919, <br/>080-22208407</p>
            </div>
            {/* Bhopal */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Bhopal</h4>
              <p className='py-2 px-3'>
              Central Regional Office,<br />
              All India Council For Technical Education,<br />
              Airport Bypass Road,<br/>
              Gandhi Nagar, Bhopal – 462 036
              </p>
              <p className='px-3'><strong>Phone:</strong> 0755-2744313-16</p>
            </div>
            {/* Vadodara */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Vadodara</h4>
              <p className='py-2 px-3'>
              All India Council For Technical Education A-1-2, Quarters,Chameli Baug, <br/>
              Campus of M.S.University Baroda, Vadodara- 39002 (Gujarat)
              </p>
              <p className='px-3'><strong>Phone:</strong> 0265-2750648, 2750614</p>
            </div>
            {/* Chandigarh */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Chandigarh</h4>
              <p className='py-2 px-3'>
              AICTE – North Western Regional Office Building,
              Dakshin Marg, Sector  36-A,<br/>Chandigarh – 160 036
              </p>
              <p className='px-3'><strong>Phone:</strong> 0172-2613326, 2661201</p>
            </div>
            {/* Kanpur */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Kanpur</h4>
              <p className='py-2 px-3'>
              AICTE - Northern Regional Office, Government Polytechnic Campus Adjoining Directorate of Tech. Education Office, Vikas Nagar, Zoo Road, <br/>Kanpur-208024
              </p>
              <p className='px-3'><strong>Phone:</strong> 0512-2585012, 2585014, 2585018</p>
            </div>
            {/* Kolkata */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Kolkata</h4>
              <p className='py-2 px-3'>
              AICTE – Eastern Regional Office Govt. College Of Engineering  & Leather Technology Campus, LB Block, Sector – III, Salt Lake City, <br/>Kolkata – 700 098
              </p>
            </div>
            {/* Hyderabad */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Hyderabad</h4>
              <p className='py-2 px-3'>
              AICTE – South Central Regional Office, West Block Nos. 201-204 & 221 to 224, 2nd Floor, Swarna Jayanti Commercial Complex Ameerpet,<br/> Hyderabad - 500038
              </p>
              <p className='px-3'><strong>Phone:</strong> 040-23341036, 040-23340113, <br/>040-23345071</p>
            </div>
            {/* Guwahati */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Guwahati</h4>
              <p className='py-2 px-3'>
              AICTE North East Regional Office, <br/>Assam Engineering College Campus, Jalukbari, <br/>Guwahati, Assam - 781013
              </p>
              <p className='px-3'><strong>Phone:</strong> 0361-2570104</p>
            </div>
            {/* THRIRUVANANTHAPURAM */}
            <div className='border-2 border-black pb-3'>
              <h4 className='bg-contact-name-bg py-2 px-3 capitalize text-center'>Thriruvananthapuram</h4>
              <p className='py-2 px-3'>
              College of Engineering, Thiruvananthapuram
              </p>
              <p className='px-3'><strong>Phone:</strong> 0471-2596363, 2594343, 2592323</p>
            </div>
        </section>
        

        {/* Google Maps */}
        <div className='h-full'>
          <GoogleMapReact />
        </div>
        
        <Footer />
        
    </div>
  )
}

export default Contact