// Libraries
import React from "react";
import PropTypes from "prop-types";

// CSS
import "./youtubeEmbed.css";

// Source:
// https://dev.to/bravemaster619/simplest-way-to-embed-a-youtube-video-in-your-react-app-3bk2

// YouTube Embed
const YouTube = ({ embedId, height, width }) => (
  <div className="video-responsive">
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YouTube.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YouTube;