// Importing Main Component
import React from 'react';
import ReactDOM from "react-dom";

import Foo from '../../components/Foo'
import Bar from '../../components/Bar'

// Swiper
import "swiper/css/bundle";

import { Circulars, GoogleMapReact, HomepageSlider, Nav2, Quicklinks, } from '../../components';

import { ExternalLink } from 'react-external-link';
import flag_dark from '../../assets/icons/Flag.svg';


function Test  ()  {
  const [ann_text] = React.useState([
    {
      text: "News & Announcements Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020",
      // acc_links: "https://www.aicte-india.org/sites/default/files/Public%20Notice%20for%20website%20English%20and%20Hindi%20%281%29%20%281%29.pdf"
    },
    {
      text: "News & Announcements Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020",
      // acc_links: "https://www.aicte-india.org/sites/default/files/Revised%20Ciruclar%20Academic%20Year%202022-23.pdf"
    },
    {
      text: "News & Announcements Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020",
      // acc_links: "https://www.aicte-india.org/sites/default/files/AICTE_Cal_2022_Final_31.12.2021_compressed_0.pdf"
    },
    {
      text: "News & Announcements Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 24 April, 2020",
      // acc_links: "https://www.aicte-india.org/sites/default/files/Advt-Consultant.pdf"
    },
    
  ]);

  function Announce({ todo, index }) {
    return (
        <div className="bg-white ">
            <div className="flex  gap-3 py-5 px-2 border-b-2">
                {/* <img src={flag_dark} alt="img tag"/> */}
                <ExternalLink href={todo.acc_links}><span className="inline-block">{todo.text}</span></ExternalLink>
            </div>
        </div>
    );
  }
  return (
    <div className='bg-white'>
      <Nav2 />
      <HomepageSlider className="h-full" />
      <div className='w-100 h-full'>
            <GoogleMapReact />
      </div>
    </div>
  );
}

const Components ={
  foo: Foo,
  bar: Bar
};


export default Test;
// const Test = () => {
//   return (
//     <div className='bg-white'>
//       <Nav2 />
//       {/* <HomepageSlider className="h-full" /> */}
//       <Announcement />
      
//     </div>
//   )
// }

// export default Test