// Dark Mode in React
// Source: https://www.youtube.com/watch?v=znU2RD0vGJY
// Github: https://github.com/ishan-me/tailwind_darkmode/blob/main/src/components/toggle.js

// Importing React
import React from 'react';

// Importing ThemeContext
import { ThemeContext } from './ThemeContext';

// Importing React Icons
import {BiMoon, BiSun} from 'react-icons/bi';

const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);

  return (
    <div>
      {theme === 'dark' ? (
        <button onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
            <BiSun />
        </button>
      ) : (
        <button
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className="text-gray-500 dark:text-gray-400 bg-white focus:outline-none shadow-none p-2 text-lg rounded-full outline-none ring-transparent cursor-pointer">
            <BiMoon />
        </button>
      )}
    </div>
  );
};

export default Toggle;