// Main Dependancies
import React from 'react'

// Importing Components
import {HomepageSlider, PageHeader2, PageHeader3,Footer, Nav2, Initiatives} from '../../components';

// Stylesheets
import './Homepage.css'

// Main Rendering-Processor
const Homepage = () => {
  return (
    <main>
      <Nav2 />
      <HomepageSlider />
      <PageHeader2 />
      <PageHeader3 />
      <Initiatives />
      <Footer />
    </main>
  );
}


export default Homepage