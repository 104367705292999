import React from "react";
import './Bulletins.css'

// Importing Components
import {Nav2, Footer} from '../../components';

// Importing Header
import {Header1} from '../../components';

  
import announcementLogo from "../../assets/announcement/announcement_logo.svg"
import advertisementLogo from "../../assets/announcement/advertisement_logo.svg"
import circularLogo from "../../assets/announcement/circular_logo.svg"
import tenderLogo from "../../assets/announcement/tender_logo.svg"
import newsletterImg from "../../assets/announcement/aicte_newsletter.svg"
import datebox from '../../assets/datebox.svg'


const Bulletins = () => {
  return (
    <div className="bulletin_main_head bg-white dark:text-white dark:bg-slate-900 rounded-lg  ring-1 ring-slate-900/5 shadow-xl">
      <Nav2/>
      <Header1 name="BULLETINS" quote="This page displays all the advertisements related to walk-in interviews, auctions, buying and selling, official notices and much more. The circulars provide information related to admission, new initiatives  events and every important announcement. The tenders include details on specific RFPs for outsourcing, empanelment, advertising agencies, etc."/>


      <div className="grid md:grid-cols-4 grid-cols-1 mt-10">
        
        <div className=" col-span-1 text-center font-medium mx-8">
          <div >
            <h3 className='font-serif dark:text-amber-300'>BULLETINS</h3>
          </div>

          <div className="greenlin text-center px-2">
           <ul className='pb-3'>
             <li>ANNOUNCEMENTS</li>
             <li>ADVERTISEMENTS</li>
             <li> CIRCULAR</li>
             <li>TENDERS</li>
             <li>NEWSLETTERS</li>
           </ul>
          </div>
        </div>
        <div className="col-span-3 grid grid-cols-6 mr-6 border-black border-2">
          <h3 className="col-span-6 text-center font-serif dark:text-amber-300">ANNOUNCEMENTS</h3>
          <div className="lg:col-span-5 col-span-6 mb-4 lg:order-1 order-2">
          
            <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
              <div className="w-max h-max">
                <img src={datebox} alt="" />
              </div>
              <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
            </div>
            <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
              <div className="w-max h-max">
                <img src={datebox} alt="" />
              </div>
              <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
            </div>
            <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
              <div className="w-max h-max">
                <img src={datebox} alt="" />
              </div>
              <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
            </div>
            <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
              <div className="w-max h-max">
                <img src={datebox} alt="" />
              </div>
              <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
            </div>
          </div>
          <img className="pt-4 lg:order-2 order-1 mx-auto lg:col-span-1 col-span-6" src={announcementLogo} alt="" />
          <button className='w-max bg-black border-2 mx-5 mb-3 px-3 py-2 m-1 order-3'>Read More</button>
        </div>
      </div>
      <div className="grid grid-cols-4 mt-20 border-b-black border-2 mx-7">
        <div className="lg:col-span-3 col-span-4 order-2 lg:order-1">
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
        </div>
        <div className="my-auto order-1 col-span-4 lg:col-span-1 lg:order-2">
          <h3 className="text-center dark:text-amber-300">ADVERTISEMENTS</h3>
          <img className="mx-auto mt-5" src={advertisementLogo} alt="" />
        </div>
        
      </div>

      <div className="grid grid-cols-4 mt-20 border-b-black border-2 mx-7">
        <div className="my-auto col-span-4 lg:col-span-1 order-1">
          <h3 className="text-center dark:text-amber-300">CIRCULARS</h3>
          <img className="mx-auto mt-5" src={circularLogo} alt="" />
        </div>
        <div className="lg:col-span-3 col-span-4 order-2">
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
        </div>

        
      </div>


      <div className="grid grid-cols-4 mt-20 border-b-black border-2 mx-7 mb-5">
        <div className="lg:col-span-3 col-span-4 order-2 lg:order-1">
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row border-b-2 border-black">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 col-span-5 mx-5 pb-4 mt-5 flex flex-row">
            <div className="w-max h-max">
              <img src={datebox} alt="" />
            </div>
            <div className="sm:col-span-3 lg:col-span-7">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea nihil perspiciatis vitae aut modi asperiores iure molestias tenetur hic doloremque, numquam quia assumenda pariatur optio voluptas inventore nam incidunt corrupti debitis explicabo sit officia doloribus.</div>
          </div>
        </div>
        <div className="my-auto order-1 col-span-4 lg:col-span-1 lg:order-2">
          <h3 className="text-center dark:text-amber-300">TENDERS</h3>
          <img className="mx-auto mt-5" src={tenderLogo} alt="" />
        </div>
        
      </div>

      <div className="mx-7 my-10">
        <h3 className="underline font-serif dark:text-amber-300">AICTE NEWSLETTERS</h3>
        <div className="grid md:grid-cols-5 grid-cols-3 flex flex-row">
          <div className="md:order-1 order-3"></div>
          <img className="md:order-2 col-span-2 h-full" src={newsletterImg} alt="" />
          <div className="h-full text-center md:order-3 order-2">
            <h5>Upcoming Newsletter... <br /> Volume -7; Issue 3 <br /> July- September 2021</h5>
            <button className='w-max object-bottom bg-black border-2 mx-5 mb-3 m-1 my-auto px-3 py-2 order-3'>Read More Newsletters</button>
          </div>
        </div>
      </div>


      <Footer/>
    </div>
  );
};
  
export default Bulletins;